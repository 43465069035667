.App {
  text-align: center;
  min-width: 100%;
}

.Paper-container {
  min-width: 100%;
  background-color: "white";
}

.App-Logo {
  height:40px;
  width:40px;
  margin-right:10px;
}

.App-Banner {
  width:100%;
}

.App-Box {
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-width: 100%;
  padding-left:0;
  padding-right:0;
}

.App-link {
  color: #61dafb;
  min-width: 100%;
  padding-left:0;
  padding-right:0;
}
